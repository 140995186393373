import React, { useEffect, useState } from "react";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { useHistory, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button, Modal } from "react-bootstrap";
import StepCategoryTemplate from "./templateStepsData/StepCategoryTemplate";
import RadioCheckTemplate from "./templateStepsData/RadioCheckTemplate";
import CategoryCheckTemplate from "./templateStepsData/CategoryCheckTemplate";
import DialogBox from "../../inspection/DialogBox";
import ViewPopup from "../../inspection/InspectionSteps/StepsData/ViewPopup";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const TemplateSteps = () => {
  const MobileCheck = window.screen.availWidth < 600;
  const history = useHistory();
  const [templateStepCategoryId, setTemplateStepCategoryId] = useState(null);
  const [tabKey, setTabKey] = useState("description");
  const type = useSelector((state) => state.type.value);
  const headTitle = useSelector((state) => state.type.title);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogFor, setDialogFor] = useState();
  const token = localStorage.getItem("access_token");
  const [categoryValue, setCategoryValue] = useState();
  const [addCategoryValue, setAddCategoryValue] = useState("");
  const [deleteLabel, setDeleteLabel] = useState();
  const [commentData, setCommentData] = useState({});
  const { id, stepId } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [subItems, setSubItems] = useState([]);
  const [showVeiwPopup, setShowVeiwPopup] = useState(false);
  const [updateTemp, setUpdateTemp] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleClose = () => {
    setShowDialog(false);
  };
  const handleShow = () => setShowDialog(true);

  const [showItemAdd, setShowItemAdd] = useState(false);
  const handleAddItem = () => {
    setShowItemAdd(true);
  };
  const handleCloseAddItem = () => setShowItemAdd(false);
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  // /////////////////////////////////////////////////////////////////////////////////////

  // Main Category

  const handleAddCategory = () => {
    const newCategory = {
      title: inputValue,
      type: tabKey,
      step_id: stepId,
      template_id: id,
    };

    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category/create",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: newCategory,
    };

    axios(config)
      .then((response) => {
        setInputValue("");
        handleCloseAddItem();
        setUpdateTemp((prev) => !prev);
      })
      .catch((error) => {
        console.error("There was an error adding the category:", error);
      });
  };

  const fetchCategories = async (type) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-categories-list",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id,
        step_id: stepId,
        type: type,
      },
    };

    try {
      const response = await axios(config);
      setCategoryList(response.data.data);
      if (response.data.data.length > 0) {
        setTemplateStepCategoryId(response.data.data[0].id);
      }
      console.log(response.data.data);
    } catch (error) {
      console.error("There was an error fetching the categories:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCategories(tabKey);
      if (templateStepCategoryId) {
        await fetchSubCategories(tabKey);
      }
    };

    fetchData();
  }, [updateTemp, tabKey]);

  const handleEditCategory = (template_id, title, step_id, id, type) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category/update",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: template_id,
        step_category_id: id,
        title: title,
        step_id: step_id,
        type: type,
      },
    };
    axios(config)
      .then((response) => {
        fetchCategories(tabKey);
      })
      .catch((error) => {
        console.error("There was an error editing the category:", error);
      });
  };

  const handleDeleteCategory = (id, template_id) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: template_id,
        step_category_id: id,
      },
    };
    axios(config)
      .then((response) => {
        fetchCategories(tabKey);
      })
      .catch((error) => {
        console.error("There was an error deleting the category:", error);
      });

    setUpdateTemp((prev) => !prev);
  };

  const handleAddSubCategory = (template_id, inputValue, step_id, id, type) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category-checks/create",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },

      data: {
        template_id: template_id,
        step_id: step_id,
        template_step_category_id: id,
        title: inputValue,
        checked: false,
        type: type,
      },
    };
    axios(config)
      .then((response) => {
        fetchSubCategories(type);
      })
      .catch((error) => {
        console.error("There was an error adding the sub category:", error);
      });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  const fetchSubCategories = async (type) => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category-checks-list",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id,
        step_id: stepId,
        type: type,
        template_step_category_id: templateStepCategoryId,
      },
    };

    try {
      const response = await axios(config);
      setSubItems(response.data.data);
      document.body.classList.remove("loading-indicator");
      console.log(response.data.data);
    } catch (error) {
      console.error("There was an error fetching the categories:", error);
      document.body.classList.remove("loading-indicator");
    }
  };

  useEffect(() => {
    if (templateStepCategoryId) {
      fetchSubCategories(tabKey);
    }
  }, [updateTemp, tabKey, templateStepCategoryId]);

  const handleViewPopup = () => {
    setShowVeiwPopup(true);
    console.log("handle view popup");
  };
  const handleViewPopupClose = () => {
    setShowVeiwPopup(false);
  };

  const handlePopupCommentImageApi = () => {
    console.log("handle COmment Image Api");
  };
  const handleCategoryUpdation = () => {
    console.log("handle category updation");
  };
  const handleCategoryCreation = () => {
    console.log("Handle Category Creation");
  };
  const handleCategoryDeletion = (template_id, step_id, id) => {
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category-checks/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: template_id,
        step_category_id: step_id,
        template_category_check_id: id,
      },
    };
    axios(config)
      .then((response) => {
        fetchSubCategories(tabKey);
      })
      .catch((error) => {
        console.error("There was an error deleting the category:", error);
      });
  };
  const handleGetCategoryLabel = () => {
    setDialogFor("edit");
    handleShow();
  };
  const handleOpenCategoryDeletionModal = () => {
    setDialogFor("delete");
    handleShow();
  };
  const handleInspectionTabContent = (key) => {
    setTabKey(key);

    switch (key) {
      case "description":
        break;
      case "observation":
        break;
      case "recommendation":
        break;
      default:
    }
  };
  const handleUpdateSubCategory = (id) => {
    setTemplateStepCategoryId(id);
  };
  console.log(templateStepCategoryId);

  const handleCheckChange = async (item) => {
    document.body.classList.add("loading-indicator");
    console.log("handle check change");

    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category-checks/update",
      headers: {
        Authorization: `Bearer ${token}`, // Ensure you have the `token` available
        "Content-Type": "application/json",
      },
      data: {
        template_id: item.template_id,
        title: item.title,
        type: item.type,
        step_id: item.step_id,
        template_step_category_id: item.template_step_category_id,
        checked: !item.checked,
        template_category_check_id: item.id,
      },
    };

    try {
      const response = await axios(config);
      document.body.classList.remove("loading-indicator");
      fetchSubCategories(tabKey);
    } catch (error) {
      console.error("There was an error updating the checked state:", error);
    }
  };

  const handleSubItemEdit = (item, updatedTitle) => {
    document.body.classList.add("loading-indicator");
    const dataToUpdate = {
      title: updatedTitle,
      template_id: item.template_id,
      type: item.type,
      step_id: item.step_id,
      template_step_category_id: item.template_step_category_id,
      checked: !item.checked,
      template_category_check_id: item.id,
    };

    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-category-checks/update",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: dataToUpdate,
    };

    axios(config)
      .then((response) => {
        console.log("Category updated successfully:", response);
        document.body.classList.remove("loading-indicator");

        fetchSubCategories(tabKey);
      })
      .catch((error) => {
        console.error("There was an error updating the category:", error);
      });
  };

  console.log(type);

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container">
        <div className="side-spacing pt50 pb50 ">
          <div
            className="d-flex justify-content-between align-items-center p-2"
            style={{ borderBottom: "1px solid #CDCDCD" }}>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="float-start no-shadow pt-1 btn"
                onClick={() => history.goBack()}>
                <img
                  src="/assets/arrow-fwd-lg.png"
                  style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                  alt=""
                />
              </button>
              <h2 className="mb-0 ml20 text-transform-capitalize">
                {headTitle || "Steps"}
              </h2>
            </div>

            <label className="cursor-pointer">
              <input
                type="checkbox"
                // defaultChecked={notApplicableChecked}
                className="mr10 form-check-input"
                checked={type === "optional"}
                // onClick={handleIfApplicable}
              />
              Not Applicable
            </label>
          </div>

          <br />
          <br />
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => handleInspectionTabContent(k)}
            transition={false}
            id="noanim-tab-example"
            className="mb-3 insp-tabs">
            <Tab eventKey="description" title="Description" className="mt50">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {categoryList.map((category, index) => {
                      return (
                        <StepCategoryTemplate
                          tabName={"description"}
                          MobileCheck={MobileCheck}
                          key={index}
                          data={category}
                          onEdit={handleEditCategory}
                          onDelete={handleDeleteCategory}
                          addSubItem={handleAddSubCategory}
                          handleUpdateSubCategory={handleUpdateSubCategory}
                          activeCategoryStep={templateStepCategoryId}
                        />
                      );
                    })}
                    <div
                      style={{
                        display: tabKey === "observation" ? "none" : "",
                        cursor: "pointer",
                      }}
                      className="mt-4 ml-4"
                      onClick={handleAddItem}>
                      <img src="/assets/icon/addsign.png" alt="" />{" "}
                      <span style={{ fontWeight: "500", fontSize: "18px" }}>
                        Add
                      </span>
                    </div>
                  </div>
                </div>
                {!MobileCheck && (
                  <div className="col-md-6">
                    <>
                      {subItems.map((subItem, index) => {
                        return (
                          <CategoryCheckTemplate
                            data={subItem}
                            key={index}
                            tabName={"description"}
                            handleGetCategoryLabel={handleGetCategoryLabel}
                            handleOpenCategoryDeletionModal={
                              handleOpenCategoryDeletionModal
                            }
                            handleCategoryDeletion={handleCategoryDeletion}
                            handleCheckChange={handleCheckChange}
                            handleSubItemEdit={handleSubItemEdit}
                            tabKey={tabKey}
                          />
                        );
                      })}
                    </>
                  </div>
                )}
              </div>
            </Tab>
            <Tab eventKey="observation" title="Observation" className="mt50">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {categoryList.map((category, index) => {
                      return (
                        <StepCategoryTemplate
                          tabName={"observation"}
                          MobileCheck={MobileCheck}
                          key={index}
                          data={category}
                          onEdit={handleEditCategory}
                          onDelete={handleDeleteCategory}
                          addSubItem={handleAddSubCategory}
                          handleUpdateSubCategory={handleUpdateSubCategory}
                          activeCategoryStep={templateStepCategoryId}
                        />
                      );
                    })}
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="mt-4 ml-4"
                      onClick={handleAddItem}>
                      <img src="/assets/icon/addsign.png" alt="" />{" "}
                      <span style={{ fontWeight: "500", fontSize: "18px" }}>
                        Add
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {subItems.map((subItem, index) => {
                    return (
                      <RadioCheckTemplate
                        key={index}
                        data={subItem}
                        handleCheckChange={handleCheckChange}
                        handleViewPopup={handleViewPopup}
                        handleSubItemEdit={handleSubItemEdit}
                        handleCategoryDeletion={handleCategoryDeletion}
                      />
                    );
                  })}
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="recommendation"
              title="Recommendation"
              className="mt50">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {categoryList.map((category, index) => {
                      return (
                        <StepCategoryTemplate
                          tabName={"recommendation"}
                          MobileCheck={MobileCheck}
                          key={index}
                          data={category}
                          onEdit={handleEditCategory}
                          onDelete={handleDeleteCategory}
                          addSubItem={handleAddSubCategory}
                          handleUpdateSubCategory={handleUpdateSubCategory}
                          activeCategoryStep={templateStepCategoryId}
                        />
                      );
                    })}
                    <div
                      style={{
                        display: tabKey === "observation" ? "none" : "",
                        cursor: "pointer",
                      }}
                      className="mt-4 ml-4"
                      onClick={handleAddItem}>
                      <img src="/assets/icon/addsign.png" alt="" />{" "}
                      <span style={{ fontWeight: "500", fontSize: "18px" }}>
                        Add
                      </span>
                    </div>
                  </div>
                </div>
                {!MobileCheck && (
                  <div className="col-md-6">
                    {subItems.map((subItem, index) => {
                      return (
                        <CategoryCheckTemplate
                          data={subItem}
                          key={index}
                          tabName={"description"}
                          handleGetCategoryLabel={handleGetCategoryLabel}
                          handleOpenCategoryDeletionModal={
                            handleOpenCategoryDeletionModal
                          }
                          handleCheckChange={handleCheckChange}
                          handleCategoryDeletion={handleCategoryDeletion}
                          handleSubItemEdit={handleSubItemEdit}
                          tabKey={tabKey}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
          <div></div>
          {/*<div className="w-100 flex align-items-center justify-content-center text-center my-3">*/}
          {/*  {" "}*/}
          {/*  <Button*/}
          {/*    variant="primary w-25 mx-auto"*/}
          {/*    className="btn-submit p5 br4">*/}
          {/*    Save*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </div>
      {dialogFor === "add" ? (
        <DialogBox
          title="Add Category"
          inputLabel="Enter Category Name"
          inputType="text"
          inputName="add_category"
          placeholder="Enter name"
          categoryValue={addCategoryValue}
          setCategoryValue={setAddCategoryValue}
          handleCategoryApi={handleCategoryCreation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "edit" ? (
        <DialogBox
          title="Edit Category"
          inputLabel="Update Name"
          inputType="text"
          inputName="update_category"
          placeholder="Enter name"
          categoryValue={categoryValue}
          setCategoryValue={setCategoryValue}
          handleCategoryApi={handleCategoryUpdation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "delete" ? (
        <DialogBox
          title="Delete Category"
          bodyText={`Do you really want to delete ${deleteLabel} `}
          handleCategoryApi={handleCategoryDeletion}
          status="delete"
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : (
        ""
      )}

      <Modal show={showItemAdd} onHide={handleCloseAddItem} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <input
            type="text"
            placeholder="Enter Name"
            style={{
              border: "none",
              borderBottom: "1px solid #CDCDCD",
              width: "100%",
            }}
            onChange={(e) => handleChange(e)}
          />
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            variant="secondary"
            className="m-0 w-25 mx-2 "
            onClick={handleCloseAddItem}>
            Cancel
          </Button>
          <Button
            className="m-0 w-25 btn-submit mx-2 "
            variant="primary"
            onClick={handleAddCategory}>
            Next
          </Button>
        </Modal.Footer>{" "}
        {showVeiwPopup && (
          <ViewPopup
            handleClose={handleViewPopupClose}
            show={showVeiwPopup}
            data={commentData}
            tabName={tabKey}
            handlePopupCommentImageApi={handlePopupCommentImageApi}
          />
        )}
      </Modal>
    </>
  );
};

export default TemplateSteps;
