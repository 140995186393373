import React from "react";
import { Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./components/AuthForms/SignIn/SignIn";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Dashboard from "./components/admin/dashboard/Dashboard";
import Inspection from "./components/admin/inspection/Inspection";
import InspectionList from "./components/admin/inspection/InspectionList";
import Reports from "./components/admin/reports/Reports";
import axios from "axios";
import Register from "./components/AuthForms/Register/Register";
import MyAccount from "./components/admin/dashboard/MyAccount/MyAccount";
import LandingPage from "./components/LandingPage/LandingPage";
import AddClient from "./components/admin/clients/AddClient";
import ArchiveClients from "./components/admin/clients/ArchiveClients";
import ClientsList from "./components/admin/clients/ClientsList";
import InspectionSteps from "./components/admin/inspection/InspectionSteps/InspectionSteps";
import InspectionStepData from "./components/admin/inspection/InspectionSteps/StepsData/InspectionStepData";
import EditClient from "./components/admin/clients/EditClient";
import UpdateUser from "./components/admin/dashboard/MyAccount/UpdateUser";
import ForgotPassword from "./components/AuthForms/ForgotPassword/ForgotPassword";
import ChangePassword from "./components/admin/dashboard/MyAccount/ChnagePassword/ChangePassword";
import InspectionPropertyDescription from "./components/admin/inspection/InspectionPropertyDescription";
import ReportHistory from "./components/admin/reports/ReportHistory";
import TermsOfUse from "./components/TermsOfUse";
import ReviewInspection from "./components/admin/inspection/ReviewInspection/ReviewInspection";
import Plans from "./components/admin/Billing/Plans";
import Checkout from "./components/admin/Billing/CheckOut/CheckoutPage";
import BillingHistory from "./components/admin/Billing/BillingHistory";
import Success from "./components/admin/Billing/Success";
import RedirectPage from "./components/Redirect/RedirectPage";
import BillingMobile from "./components/BillingMobile";
import AdminPanel from "./components/admin/admin-panel/AdminPanel";
import ReportArchives from "./components/admin/Archives/ReportArchives";
import NotFound404 from "./utils/NotFound404";
import FBTest from "./FBTest";
import Testpage from "./Testpage";
import EmailLogs from "./components/admin/Logs/EmailLogs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Templates from "./components/admin/templates/Templates";
import TemplateDetails from "./components/admin/templates/templateDetails/TemplateDetails";
import TemplateSteps from "./components/admin/templates/templateSteps/TemplateSteps";
import TemplateSelection from "./components/admin/templates/templateSelection";

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = process.env.REACT_APP_PROD_API_URL;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_DEV_API_URL;
}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/clients" exact component={ClientsList} />
          <Route path="/inspection" exact component={Inspection} />
          <Route path="/inspection-list" exact component={InspectionList} />
          <Route path="/reports" exact component={Reports} />
          <Route path="/report-archives" exact component={ReportArchives} />
          <Route path="/reports-history" exact component={ReportHistory} />
          <Route path="/templates" exact component={Templates} />
          <Route
            path="/template-details/:id"
            exact
            component={TemplateDetails}
          />
          <Route
            path="/template-steps/:id/:stepId"
            exact
            component={TemplateSteps}
          />
          <Route
            path="/select-template/:id"
            exact
            component={TemplateSelection}
          />
          <Route path="/my-account" exact component={MyAccount} />
          <Route path="/my-account/edit-profile" exact component={UpdateUser} />
          <Route path="/clients/add-client" exact component={AddClient} />
          <Route path="/clients/edit-client/:id" exact component={EditClient} />
          <Route
            path="/clients/archived-clients"
            exact
            component={ArchiveClients}
          />
          <Route
            path="/inspection/:id/inspection-steps/:clientId"
            exact
            component={InspectionSteps}
          />
          <Route
            path="/inspection-step-data/:id/:name/:bookmark_id"
            exact
            component={InspectionStepData}
          />
          <Route
            path="/my-account/change-password"
            exact
            component={ChangePassword}
          />
          <Route
            path="/property-description/:id"
            exact
            component={InspectionPropertyDescription}
          />
          <Route
            path="/inspection-review/:id/:clientId"
            exact
            component={ReviewInspection}
          />
          <Route path="/plans" exact component={Plans} />
          <Route path="/checkout/:id" exact component={Checkout} />
          <Route path="/billinghistory" exact component={BillingHistory} />
          <Route path="/success" exact component={Success} />
          <Route path="/admin" exact component={AdminPanel} />
          <Route path="/fblogin" exact component={FBTest} />
          <Route path="/testpage" exact component={Testpage} />
          <Route path="/mail-logs" exact component={EmailLogs} />
          <>
            <Row className="h-100">
              <Col md={1}></Col>
              <Col md={10} className="position-relative">
                <div className="content-wrapper">
                  <Route path="/redirection" exact component={RedirectPage} />
                  <Route path="/" exact component={LandingPage} />

                  <Route path="/login" exact component={SignIn} />
                  <Route path="/register" exact component={Register} />
                  <Route
                    path="/privacy-policy"
                    exact
                    component={PrivacyPolicy}
                  />
                  <Route path="/terms-of-use" exact component={TermsOfUse} />
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPassword}
                  />
                  <Route path="/billing/:id" exact component={BillingMobile} />
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          </>
          <Route path="*" component={NotFound404} />
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}
export default App;
