import CategoryCheck from "./CategoryCheck";
const StepCategory = (props) => {
  const { id, title, inspection_step_id } = props.data;
  const {
    handleActiveCategoryStep,
    activeCategoryStep,
    tabName,
    handleOpenCategoryCreationModal,
    inspectionStepChecks,
    activeCategory,
    MobileCheck,
    handleChangeInCheckbox,
    handleGetCategoryLabel,
    handleOpenCategoryDeletionModal,
    handleViewPopupGetValues,
  } = props;
  let FirstLetter = title[0].toUpperCase();
  let SecondLetter = title[1].toUpperCase();

  const handleIconShow = (id, tabName) => {
    handleActiveCategoryStep(id, tabName);
  };
  return (
    <>
      <div
        className={
          "d-flex align-items-center justify-content-between circle-card-ct cursor-pointer w-100 " +
          (activeCategoryStep === id ? "active" : "")
        }
        id={id}
        onClick={() => handleIconShow(id, tabName)}
      >
        <div className="d-flex align-items-center">
          <div className="float-start">
            <div className="circle-name  mr10">
              <div> {FirstLetter + SecondLetter}</div>
            </div>
          </div>
          <h5 className="mb-0 d-flex break-word">{title}</h5>
        </div>

        {activeCategoryStep === id && (
          <div
            onClick={() => {
              handleOpenCategoryCreationModal(inspection_step_id, id);
              console.log(inspection_step_id, id);
            }}
            className={tabName === "observation" ? "hide" : ""}
          >
            <img
              style={{ height: 40 }}
              src="/assets/icon/add.png"
              alt="add category"
            />
          </div>
        )}
      </div>
      {inspectionStepChecks &&
        MobileCheck &&
        activeCategory === id &&
        inspectionStepChecks.map((check, index) => (
          <CategoryCheck
            key={index}
            data={check}
            tabName={tabName}
            handleChangeInCheckbox={handleChangeInCheckbox}
            handleGetCategoryLabel={handleGetCategoryLabel}
            handleViewPopupGetValues={handleViewPopupGetValues}
            handleOpenCategoryDeletionModal={handleOpenCategoryDeletionModal}
          />
        ))}
    </>
  );
};

export default StepCategory;
