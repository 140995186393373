import React, { useEffect, useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { setValue } from "../../../redux/reducers/templateSlice";
import { useDispatch } from "react-redux";

const TemplateSelection = () => {
  const dispatch = useDispatch();
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [customTemplates, setCustomTemplates] = useState([]);
  let token = localStorage.getItem("access_token");
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchTemplates = async () => {
      const config = {
        method: "get",
        url: "/v1/oauth/templates/list",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(config);
        const allTemplates = response.data.data;
        
        // Separate default and custom templates
        const defaultTemp = allTemplates.find(temp => temp.id === 1);
        const customTemp = allTemplates.filter(temp => temp.id !== 1);
        
        setDefaultTemplate(defaultTemp);
        setCustomTemplates(customTemp);
      } catch (error) {
        console.error("There was an error fetching the templates", error);
      }
    };

    fetchTemplates();
  }, [token]);

  const handleDispatch = (id) => {
    dispatch(setValue(id));
    history.push(`/property-description/${params.id}`);
  };

  const TemplateItem = ({ data }) => (
    <div
      key={data.id}
      onClick={() => handleDispatch(data.id)}
      className="d-flex justify-content-between align-items-center mb-2"
      style={{
        boxShadow: "0px 0px 5.8px 0px rgba(0, 0, 0, 0.25)",
        padding: "16px",
        borderRadius: "8px",
        cursor: "pointer"
      }}>
      <div>{data.title}</div>
    </div>
  );

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <h2 className="float-start">Select Template</h2>
          </div>

          <div className="mb-4">
            <h3>Default Template</h3>
            {defaultTemplate && <TemplateItem data={defaultTemplate} />}
          </div>

          <div>
            <h3>Custom Templates</h3>
            {customTemplates.map((data) => (
              <TemplateItem key={data.id} data={data} />
            ))}
          </div>
        </Row>
      </div>
    </>
  );
};

export default TemplateSelection;