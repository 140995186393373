import React, { useState } from "react";
import ScrollToTop from "../../ScrollToTop";
import NavbarDashboard from "../NavbarDashboard";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const InspectionPropertyDescription = (props) => {
  var token = localStorage.getItem("access_token");
  var history = useHistory();
  const { id } = useParams();
  const inspectionExistance = useSelector(
    (state) => state.inspection.checkInspectionExistanceData
  );
  const inspection_template_id = useSelector((state) => state.template.value);

  const paragraph_text = inspectionExistance.template.paragraph_text;
  const template_id = inspectionExistance.template.id;
  var client_id = id;

  const [description, setDescription] = useState(paragraph_text);
  const initialValues = {
    paragraph_text: description,
    client_id: client_id,
    template_id: template_id,
    inspection_template_id: inspection_template_id,
  };

  const handleFormSubmit = (values) => {
    var createInspection = {
      method: "post",
      url: "/v1/oauth/inspection/create",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    };
    axios(createInspection)
      .then((res) => {
        const inspection_id = res.data.inspection_id;
        if (res.data.status === true) {
          history.push({
            pathname: `/inspection/${inspection_id}/inspection-steps/${client_id}`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const validate = Yup.object().shape({
    paragraph_text: Yup.string().required("Property Description is required"),
  });

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <div className="side-spacing pt50 ">
          <div className="p-0">
            <h2 className="mb20">Inspection</h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={handleFormSubmit}>
            {(props) => {
              const {
                values,
                touched,
                errors,
                // isSubmitting,
                handleChange,
                handleSubmit,
                handleBlur,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <label htmlFor="description" className="mb10">
                    Property Description
                  </label>
                  <Form.Control
                    as="textarea"
                    id="description"
                    rows={8}
                    name="paragraph_text"
                    onChange={(e) => {
                      handleChange(e);
                      setDescription(e.target.value);
                    }}
                    className="mb10"
                    onBlur={handleBlur}
                    style={{ color: "grey" }}
                    value={values.paragraph_text}
                  />
                  {errors.paragraph_text && touched.paragraph_text && (
                    <small className="input-error">
                      {errors.paragraph_text}
                    </small>
                  )}
                  <br />
                  <br />
                  <div className="text-center">
                    <button type="submit" className="btn btn-submit w-100">
                      Save
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default InspectionPropertyDescription;
