import React, { useState } from "react";
import ScrollToTop from "../../../../ScrollToTop";
import NavbarDashboard from "../../../NavbarDashboard";
import Tabs from "react-bootstrap/Tabs";
import { useHistory, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import StepCategory from "./Categories/StepCategory";
import RadioCheck from "./Categories/RadioCheck";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInspectionStepsApi,
  getInspectionStepsCategoryChecksApi,
} from "../../../../../redux/reducers/inspectionSlice";
import axios from "axios";
import DialogBox from "../../DialogBox";
import ViewPopup from "./ViewPopup";
import CategoryCheck from "./Categories/CategoryCheck";

const InspectionStepData = () => {
  const MobileCheck = window.screen.availWidth < 600;

  const dispatch = useDispatch();
  const history = useHistory();
  const { id, bookmark_id } = useParams();
  const { name: stepName } = useParams();
  const [tabKey, setTabKey] = useState("description");
  var token = localStorage.getItem("access_token");

  const [addCategoryValue, setAddCategoryValue] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [dialogFor, setDialogFor] = useState();
  const [deleteLabel, setDeleteLabel] = useState();
  const [categoryValue, setCategoryValue] = useState();
  const [showVeiwPopup, setShowVeiwPopup] = useState(false);

  const [commentData, setCommentData] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [inspectionMainStepData, setInspectionMainStepData] = useState({});
  const [mainId, setMainId] = useState(null);
  const [checkId, setCheckId] = useState(null);
  const [inspectionStepCategoryId, setInspectionStepCategoryId] = useState();

  const handleClose = () => {
    setShowDialog(false);
    setAddCategoryValue("");
  };
  const handleShow = () => setShowDialog(true);

  const handleViewPopupClose = () => {
    setShowVeiwPopup(false);
  };
  const handleViewPopupShow = () => {
    setShowVeiwPopup(true);
  };

  const inspectionStepData = useSelector(
    (state) => state.inspection.storeActiveInspectionStep
  );
  const is_applicable = inspectionStepData?.data?.is_applicable;
  const [notApplicableChecked, setNotApplicableChecked] = useState();
  const inspectionStepChecks = inspectionStepData?.checksDataFor?.data?.checks;
  const inspectionStepTab = inspectionStepData?.checksDataFor?.tabName;
  const checksData = useSelector(
    (state) => state.inspection.storeActiveInspectionStep.checksDataFor.data
  );

  const [activeCategoryStep, setActiveCategoryStep] = useState(null);
  const applicable = useSelector((state) => state.template.is_applicable);
  useEffect(() => {
    dispatch(getInspectionStepsApi({ id, stepName, bookmark_id, tabKey }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNotApplicableChecked(!is_applicable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_applicable]);

  const handleInspectionTabContent = (key) => {
    setTabKey(key);

    let firstIndexToGetChecks;
    if (
      key === "description" ||
      key === "observation" ||
      key === "recommendation"
    ) {
      if (inspectionMainStepData.length > 0 && inspectionMainStepData[0]?.id) {
        firstIndexToGetChecks = inspectionMainStepData[0].id;
      } else {
        console.error("No valid data found for inspectionMainStepData");
        return;
      }
    }

    if (firstIndexToGetChecks) {
      dispatch(
        getInspectionStepsCategoryChecksApi({
          id,
          step_id: bookmark_id,
          type: tabKey,
          inspection_step_category_id: firstIndexToGetChecks,
        })
      );
      setActiveCategoryStep(firstIndexToGetChecks);
    }
  };

  const handleActiveCategoryStep = (categoryName, tabName) => {
    setActiveCategory(categoryName);
    handleGetInspectionChecks(categoryName, tabName);
    setActiveCategoryStep(categoryName);
  };

  const handleGetInspectionChecks = (getCategoryName, tabName) => {
    dispatch(
      getInspectionStepsCategoryChecksApi({
        id,
        step_id: bookmark_id,
        type: tabKey,
        inspection_step_category_id: getCategoryName,
      })
    );
  };

  useEffect(() => {
    if (mainId) {
      handleActiveCategoryStep(mainId, tabKey);
    }
  }, [mainId, tabKey]);

  const handleChangeInRadio = async (
    inspection_step_category_check_id,
    check,
    label
  ) => {
    document.body.classList.add("loading-indicator");

    var data = {
      inspection_id: id,
      inspection_step_category_check_id: inspection_step_category_check_id,
      title: label,
      checked: check,
    };
    var changeInCheckboxData = {
      method: "post",
      url: "/v1/oauth/inspection/update-category-checks",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios(changeInCheckboxData)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              step_id: bookmark_id,
              type: tabKey,
              inspection_step_category_id: activeCategory,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [newMainId, setNewMainId] = useState(null);
  const [newCheckId, setNewCheckId] = useState(null);
  const handleChangeInCheckbox = async (
    inspection_step_category_check_id,
    check,
    label
  ) => {
    document.body.classList.add("loading-indicator");

    var data = {
      inspection_id: id,
      inspection_step_category_check_id: inspection_step_category_check_id,
      title: label,
      checked: check,
    };
    var changeInCheckboxData = {
      method: "post",
      url: "/v1/oauth/inspection/update-category-checks",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    await axios(changeInCheckboxData)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              step_id: bookmark_id,
              type: tabKey,
              inspection_step_category_id: activeCategory,
            })
          );
        }
        document.body.classList.remove("loading-indicator");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetCategoryLabel = (
    editLabel,
    inspection_id,
    inspection_step_id,
    inspection_step_category_id,
    type,
    id
  ) => {
    setCategoryValue(editLabel);
    setDialogFor("edit");
    setNewMainId(inspection_step_id);
    setNewCheckId(id);
    setInspectionStepCategoryId(inspection_step_category_id);
    handleShow();
  };

  const handleOpenCategoryCreationModal = (inspection_step_id, id) => {
    setDialogFor("add");
    setNewMainId(inspection_step_id);
    setNewCheckId(id);
    handleShow();
  };

  const handleCategoryCreation = async () => {
    const formData = {
      inspection_id: id,
      inspection_step_id: newMainId,
      title: addCategoryValue,
      type: tabKey,
      inspection_step_category_id: newCheckId,
    };

    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/add-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };

    try {
      const res = await axios(configValues);
      if (res.data.status === true) {
        handleClose();
        dispatch(
          getInspectionStepsCategoryChecksApi({
            id,
            step_id: bookmark_id,
            type: tabKey,
            inspection_step_category_id: activeCategoryStep,
          })
        );
      } else {
        console.error("Category creation failed:", res.data);
      }
    } catch (err) {
      console.error("Error during category creation:", err);
    } finally {
      handleClose();
    }
  };
  const handleCategoryUpdation = () => {
    const formData = {
      inspection_id: id,
      inspection_step_id: newMainId,
      inspection_step_category_id: inspectionStepCategoryId,
      title: categoryValue,
      type: tabKey,
      inspection_category_label_id: newCheckId,
    };
    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/update-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              step_id: bookmark_id,
              type: tabKey,
              inspection_step_category_id: activeCategory,
            })
          );
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpenCategoryDeletionModal = (label, id) => {
    setDialogFor("delete");
    setDeleteLabel(label);
    setCheckId(id);
    handleShow();
  };
  const handleCategoryDeletion = () => {
    const formData = {
      inspection_category_label_id: checkId,
    };
    const configValues = {
      method: "post",
      url: "/v1/oauth/inspection/delete-label",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    };
    axios(configValues)
      .then((res) => {
        if (res.data.status === true) {
          handleClose();
          dispatch(
            getInspectionStepsCategoryChecksApi({
              id,
              step_id: bookmark_id,
              type: tabKey,
              inspection_step_category_id: activeCategory,
            })
          );
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleViewPopupGetValues = (values, inspection_step_category_id) => {
    document.body.classList.add("loading-indicator");
    var requiredVals = {
      inspection_id: id,
      inpsection_category_check_id: inspection_step_category_id,
    };
    const configAxios = {
      method: "post",
      url: "v1/oauth/inspection/get-comment-images",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: requiredVals,
    };
    axios(configAxios)
      .then((res) => {
        if (res.data.status === true) {
          const resData = res.data;
          const data = { resData, values };
          setCommentData(data);
          document.body.classList.remove("loading-indicator");
          handleViewPopupShow();
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };
  const handlePopupCommentImageApi = (data, values) => {
    let images = data.uploadImages;
    document.body.classList.add("loading-indicator");
    var formDataValues = new FormData();
    formDataValues.append("selrecomd", data.radioChecked);
    formDataValues.append("inspection_id", id);
    formDataValues.append("inpsection_category_check_id", values.id);
    formDataValues.append("inspection_step_id", values.inspection_step_id);
    formDataValues.append(
      "inpsection_step_category_id",
      values.inspection_step_category_id
    );

    formDataValues.append("comment", data.comment);
    formDataValues.append("call_from", "web");
    for (var i = 0; i < images.length; i++) {
      formDataValues.append("images[]", images[i]);
    }

    const config = {
      method: "post",
      url: "/v1/oauth/inspection/save-comment-images",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formDataValues,
    };
    axios(config)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
          handleViewPopupClose();
          if (data.radioChecked.length > 0) {
            dispatch(
              getInspectionStepsCategoryChecksApi({
                id,
                step_id: bookmark_id,
                type: tabKey,
                inspection_step_category_id: activeCategory,
              })
            );
          }
        } else if (res.data.status === false) {
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const handleIfApplicable = () => {
    document.body.classList.add("loading-indicator");
    const values = {
      inspection_id: id,
      inspection_step_id: bookmark_id,
    };
    const configData = {
      method: "post",
      url: "/v1/oauth/inspection/not-applicable",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    };
    axios(configData)
      .then((res) => {
        if (res.data.status === true) {
          document.body.classList.remove("loading-indicator");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///////////////////////////  New Code According to Updating DB   /////////////////////////////////

  const fetchMainSteps = async () => {
    const formData = {
      inspection_id: id,
      step_id: bookmark_id,
      type: tabKey,
    };

    try {
      const response = await axios.post(
        "/v1/oauth/inspection/get-step-categories",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        setInspectionMainStepData(response.data.data);
        setMainId(response.data.data[0].id);
      } else {
        setInspectionMainStepData({});
      }
    } catch (error) {
      console.error("Failed to fetch main steps:", error);
    }
  };
  useEffect(() => {
    fetchMainSteps();
  }, [tabKey]);

  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />

      <div className="content-container Mcontent ">
        <div className="side-spacing pt50 pb50 ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="float-start no-shadow pt-1 btn"
                onClick={() => history.goBack()}
              >
                <img
                  src="/assets/arrow-fwd-lg.png"
                  style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                  alt=""
                />
              </button>
              <h2 className="mb-0 ml20 text-transform-capitalize">
                {inspectionStepData.name === "portfolio"
                  ? "Structure"
                  : inspectionStepData.name}
              </h2>
            </div>

            <label className="cursor-pointer">
              <input
                type="checkbox"
                defaultChecked={applicable}
                className="mr10 form-check-input"
                onClick={handleIfApplicable}
              />
              Not Applicable
            </label>
          </div>

          <br />
          <br />
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => handleInspectionTabContent(k)}
            transition={false}
            id="noanim-tab-example"
            className="mb-3 insp-tabs"
          >
            <Tab
              eventKey={"description"}
              title={"Description"}
              className="mt50"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionMainStepData &&
                      inspectionMainStepData?.length > 0 &&
                      inspectionMainStepData.map((data) => {
                        return (
                          <StepCategory
                            key={data.id}
                            data={data}
                            handleActiveCategoryStep={handleActiveCategoryStep}
                            tabName="description"
                            handleOpenCategoryCreationModal={
                              handleOpenCategoryCreationModal
                            }
                            activeCategoryStep={activeCategoryStep}
                            inspectionStepChecks={inspectionStepChecks}
                            activeCategory={activeCategory}
                            MobileCheck={MobileCheck}
                            handleChangeInCheckbox={handleChangeInCheckbox}
                            handleGetCategoryLabel={handleGetCategoryLabel}
                            handleOpenCategoryDeletionModal={
                              handleOpenCategoryDeletionModal
                            }
                          />
                        );
                      })}
                  </div>
                </div>
                {!MobileCheck && (
                  <div className="col-md-6">
                    <>
                      {checksData?.length > 0 &&
                        checksData.map((check, index) => (
                          <CategoryCheck
                            key={index}
                            data={check}
                            handleChangeInCheckbox={handleChangeInCheckbox}
                            handleGetCategoryLabel={handleGetCategoryLabel}
                            tabName="description"
                            handleOpenCategoryDeletionModal={
                              handleOpenCategoryDeletionModal
                            }
                          />
                        ))}
                      {/* <div><button style={{ width: "60%" }} className="btn-submit p5 br4">
                      Save Changes
                    </button></div> */}
                    </>
                  </div>
                )}
              </div>
            </Tab>

            <Tab
              eventKey={"observation"}
              title={" Observation"}
              className="mt50 "
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionMainStepData.length > 0 &&
                      inspectionMainStepData.map((data, index) => (
                        <StepCategory
                          key={data.id}
                          data={data}
                          handleActiveCategoryStep={handleActiveCategoryStep}
                          handleOpenCategoryCreationModal={
                            handleOpenCategoryCreationModal
                          }
                          tabName="observation"
                          activeCategoryStep={activeCategoryStep}
                        />
                      ))}
                  </div>
                </div>
                <div className="col-md-6">
                  {checksData.length > 0 &&
                    checksData.map((check, index) => (
                      <RadioCheck
                        key={index}
                        data={check}
                        handleChangeInRadio={handleChangeInRadio}
                        handleViewPopupGetValues={handleViewPopupGetValues}
                        tabName="observation"
                        handleGetCategoryLabel={handleGetCategoryLabel}
                        handleOpenCategoryDeletionModal={
                          handleOpenCategoryDeletionModal
                        }
                      />
                    ))}
                </div>
              </div>
            </Tab>

            <Tab
              eventKey={"recommendation"}
              title={"Recommendations"}
              className="mt50 "
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="stepCat-ct">
                    {inspectionMainStepData.length > 0 &&
                      inspectionMainStepData.map((data, index) => (
                        <StepCategory
                          key={index}
                          data={data}
                          MobileCheck={MobileCheck}
                          handleActiveCategoryStep={handleActiveCategoryStep}
                          handleOpenCategoryCreationModal={
                            handleOpenCategoryCreationModal
                          }
                          tabName="recommendation"
                          activeCategoryStep={activeCategoryStep}
                          inspectionStepChecks={inspectionStepChecks}
                          activeCategory={activeCategory}
                          handleChangeInCheckbox={handleChangeInCheckbox}
                          handleGetCategoryLabel={handleGetCategoryLabel}
                          handleViewPopupGetValues={handleViewPopupGetValues}
                          handleOpenCategoryDeletionModal={
                            handleOpenCategoryDeletionModal
                          }
                        />
                      ))}
                  </div>
                </div>
                {!MobileCheck && (
                  <div className="col-md-6">
                    {checksData.length > 0 &&
                      checksData.map((check, index) => (
                        <CategoryCheck
                          key={index}
                          data={check}
                          handleChangeInCheckbox={handleChangeInCheckbox}
                          handleGetCategoryLabel={handleGetCategoryLabel}
                          tabName="recommendation"
                          handleViewPopupGetValues={handleViewPopupGetValues}
                          handleOpenCategoryDeletionModal={
                            handleOpenCategoryDeletionModal
                          }
                        />
                      ))}
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {dialogFor === "add" ? (
        <DialogBox
          title="Add Category"
          inputLabel="Enter Category Name"
          inputType="text"
          inputName="add_category"
          placeholder="Enter name"
          categoryValue={addCategoryValue}
          setCategoryValue={setAddCategoryValue}
          handleCategoryApi={handleCategoryCreation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "edit" ? (
        <DialogBox
          title="Edit Category"
          inputLabel="Update Name"
          inputType="text"
          inputName="update_category"
          placeholder="Enter name"
          categoryValue={categoryValue}
          setCategoryValue={setCategoryValue}
          handleCategoryApi={handleCategoryUpdation}
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : dialogFor === "delete" ? (
        <DialogBox
          title="Delete Category"
          bodyText={`Do you really want to delete ${deleteLabel} `}
          handleCategoryApi={handleCategoryDeletion}
          status="delete"
          handleClose={handleClose}
          show={showDialog}
          handleShow={handleShow}
        />
      ) : (
        ""
      )}
      {showVeiwPopup && (
        <ViewPopup
          handleClose={handleViewPopupClose}
          show={showVeiwPopup}
          data={commentData}
          tabName={inspectionStepTab}
          handlePopupCommentImageApi={handlePopupCommentImageApi}
        />
      )}
    </>
  );
};

export default InspectionStepData;
